import { render, staticRenderFns } from "./GalleryForm.vue?vue&type=template&id=6bda75fb&scoped=true"
import script from "./GalleryForm.vue?vue&type=script&lang=js"
export * from "./GalleryForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@_929911894884efc32d6647cbd9867261/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bda75fb",
  null
  
)

export default component.exports