import { render, staticRenderFns } from "./ExerciseForm.vue?vue&type=template&id=2036d3d8&scoped=true"
import script from "./ExerciseForm.vue?vue&type=script&lang=js"
export * from "./ExerciseForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@_929911894884efc32d6647cbd9867261/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2036d3d8",
  null
  
)

export default component.exports