<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Inserisci una nuova vendita</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-stepper v-model="step" outlined>
                <v-stepper-header >
                  <v-stepper-step :complete="step > 1" step="1">Seleziona l'acquirente</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 2" step="2">Seleziona i prodotti</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 3" step="3">Dettagli di spedizione</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 4" step="4">Riepilogo</v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-row>
                      <v-col cols="12"><h2>Seleziona l'acquirente</h2></v-col>
                      <v-col cols="12" lg="6" xl="4">
                        <v-autocomplete
                            label="Utente*"
                            rounded filled
                            :items="userOptions"
                            v-model="editingItem.user_id"
                            @change="onUserSelected"
                            :error="userError"
                            :error-messages="requiredErrorMessages(userError)"
                        >

                          <template v-slot:selection="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.text"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>

                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.text"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.fiscal_code"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-space-between">
                        <div></div>
                        <v-btn :disabled="!editingItem.user_id" color="accent" @click="step = 2">Continua</v-btn>
                      </v-col>

                    </v-row>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-row>
                      <v-col cols="12"><h2>Seleziona i prodotti</h2></v-col>
                      <v-col cols="12">
                        <v-card outlined rounded class="mb-3">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left">Pacchetto</th>
                                <th class="text-left">Prodotto</th>
                                <th class="text-left">Prezzo</th>
                                <th style="width: 60px"></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(id, i) in editingItem.items" :key="`${id}_${i}`">
                                <td>{{kitName(id)}}</td>
                                <td>{{productName(id)}}</td>
                                <td>{{productPrice(id)}}</td>
                                <td><v-btn icon color="accent" @click="removeProduct(i)"><v-icon>mdi-close</v-icon></v-btn></td>
                              </tr>
                              <tr v-if="!editingItem.items.length">
                                <td colspan="4" class="text-center">non hai inserito nessun prodotto</td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <h3>Aggiungi un prodotto</h3>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-autocomplete
                            label="Pacchetto*"
                            rounded filled
                            :items="kitOptions"
                            v-model="kit_id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-autocomplete
                            label="Prodotto*"
                            rounded filled
                            :disabled="!kit_id"
                            :items="productOptions"
                            v-model="selectedProductId"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addProduct()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <h3>Aggiungi dei giorni di pausa abbonamento</h3>
                        <p class="mb-0">L'utente può ancora acquistare <strong>{{subPauseExtraAvailable}}</strong> giorni di pausa su <strong>{{settings.max_days_of_subscription_pause}}</strong> per quest'anno</p>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-text-field
                            label="Numero di giorni di pausa extra"
                            rounded filled
                            v-model="editingItem.sub_pause_days"
                            type="number"
                            step="1"
                            min="0"
                            max="20"
                            :error="subPauseError"
                            :error-messages="subPauseErrorMessages"
                        ></v-text-field>
                        <p :key="'costo'"><strong>Costo aggiuntivo</strong>: {{(settings.extra_day_of_sub_pause_price * editingItem.sub_pause_days).toFixed(2)}} €</p>
                      </v-col>
                      <v-col cols="12">
                        <h3>Opzioni extra di acquisto</h3>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-switch
                            label="Home Kit (in regalo o nell'ordine)"
                            rounded filled
                            v-model="editingItem.home_kit"
                        ></v-switch>
                      </v-col>

                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-switch
                            label="Richiesta tessera MSP (nuova o rinnovo)"
                            :hint="`La tessera ha un costo di ${settings.coni_card_price}€`"
                            persistent-hint
                            rounded filled
                            v-model="editingItem.card"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-switch
                            label="Richiesta spedizione"
                            persistent-hint
                            rounded filled
                            v-model="editingItem.expedition"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-space-between">
                        <v-btn text @click="step = 1">Indietro</v-btn>
                        <v-btn :disabled="!editingItem.items.length && !editingItem.card && !editingItem.sub_pause_days" color="accent" @click="step = expedition ? 3 : 4">Continua</v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-row>
                      <v-col cols="12"><h2>Dettagli di spedizione</h2></v-col>
                      <v-col cols="12" md="6" lg="4" xl="3" v-if="expedition">
                        <v-text-field
                            label="Indirizzo"
                            rounded filled
                            v-model="editingItem.address.name"
                            hint="Via\Piazza ..."
                            :error="addressError"
                            :error-messages="requiredErrorMessages(addressError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="8" md="4" lg="3" xl="2" v-if="expedition">
                        <v-text-field
                            label="Città"
                            rounded filled
                            v-model="editingItem.address.city"
                            :error="cityError"
                            :error-messages="requiredErrorMessages(cityError)"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" md="2" lg="2" xl="1" v-if="expedition">
                        <v-text-field
                            label="Provincia"
                            rounded filled
                            v-model="editingItem.address.province"
                            hint="es. PG"
                            :error="provError"
                            :error-messages="requiredErrorMessages(provError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3" v-if="expedition">
                        <v-text-field
                            label="CAP"
                            rounded filled
                            v-model="editingItem.address.zip_code"
                            :error="capError"
                            :error-messages="requiredErrorMessages(capError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-space-between">
                        <v-btn text @click="step = 2">Indietro</v-btn>
                        <v-btn :disabled="!editingItem.address.zip_code || !editingItem.address.province || !editingItem.address.city || !editingItem.address.name" color="accent" @click="step = 4">Continua</v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <v-row>
                      <v-col cols="12">
                        <h2>Riepilogo</h2>
                      </v-col>
                      <v-col cols="12">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12">
                        <h3 class="mb-3">Elementi nell'ordine</h3>
                        <p class="mb-0" v-for="(id, i) in editingItem.items"><strong>{{kitName(id)}} - {{productName(id)}}</strong>: {{productPrice(id)}}</p>
                        <p class="mb-0"  v-if="editingItem.sub_pause_days"><strong>{{editingItem.sub_pause_days}} giorni extra di pausa</strong>: {{(editingItem.sub_pause_days * settings.extra_day_of_sub_pause_price).toFixed(2)}} €</p>
                        <p class="mb-0"  v-if="editingItem.card"><strong>Tessera MSP</strong>: {{settings.coni_card_price}} €</p>
                      </v-col>
                      <v-col cols="12" v-if="expedition">
                        <h3 class="mb-3">Indirizzo di spedizione</h3>
                        <p class="mb-0">{{editingItem.address.name}}, {{editingItem.address.city}}, {{editingItem.address.province}}, {{editingItem.address.zip_code}}</p>
                      </v-col>
                      <v-col cols="12">
                        <h3>Informazioni sul pagamento</h3>
                      </v-col>
                      <v-col cols="12" lg="6" xl="4">
                        <v-text-field
                            label="ID di pagamento / n° ricevuta"
                            rounded filled
                            v-model="editingItem.payment_uid"
                            hint="lascia vuoto se vuoi che sia generato automaticamente"
                            persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-space-between">
                        <v-btn text @click="step = expedition ? 3 : 2">Indietro</v-btn>
                        <v-btn color="accent" @click="save()">Inserisci l'acquisto</v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>

              <!--

              <v-row>
                <v-col cols="12">
                  <h2 class="title">Dettagli ordine</h2>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Metodo di pagamento"
                          rounded filled
                          :items="paymentMethods"
                          v-model="editingItem.payment_method"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="ID di pagamento"
                          rounded filled
                          v-model="editingItem.payment_uid"
                          :error="puidError"
                          :error-messages="requiredErrorMessages(puidError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Stato ordine"
                          rounded filled
                          :items="statusOptions"
                          v-model="editingItem.status"
                  ></v-select>
                </v-col>



                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Ordine evaso"
                          rounded filled
                          v-model="editingItem.evaded"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Impediscine la modifica/cancellazione"
                          rounded filled
                          v-model="editingItem.block_edit"
                  ></v-switch>
                </v-col>

              </v-row>-->

            </v-container>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="cardDialog" max-width="500">
      <v-card>
        <v-card-title>Tessera MSP scaduta</v-card-title>
        <v-card-text>
          <p>La tessera MSP di questo utente è scaduta, vuoi aggiungere il rinnovo della tessera al carrello?<br>La tessera ha un costo di {{settings.coni_card_price}}€</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cardDialog = false;">No</v-btn>
          <v-btn text color="accent" @click="addConiCardToCart()">Sì</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import {DateTime} from "luxon";
  export default {
    name: "PurchaseWizard",
    data () {
      return {
        step : 1,
        kit_id : null,
        selectedProductId : null,
        userPurchases : [],
        settings: {
          coni_card_price : 15,
          max_days_of_subscription_pause : 10,
          extra_day_of_sub_pause_price : 3
        },
        editingItem : {
          user_id : null,
          items : [],
          expedition : false,
          sub_pause_days : 0,
          payment_method : 'manual',
          payment_uid : '',
          status : 'paid',
          address: {
            name: "",
            city: "",
            province: "",
            zip_code: ""
          },
          block_edit : false,
          home_kit : false,
          card : false,
          evaded : false,
        },
        paymentMethods: config.paymentMethods,
        userError : false,
        addressError : false,
        cityError : false,
        provError : false,
        capError : false,
        itemsError : false,
        puidError : false,
        cardDialog : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','kits','users','products','cards','pauses']),
      updating() {
        return !!this.editingItem._id
      },
      expedition : {
        get() {
          return this.editingItem.expedition
        }
      },
      statusOptions() {
        if(!this.editingItem.expedition) {
          return config.cartStatusOptions.filter((item)=>{
            return item.value !== 'paid'
          })
        }
        return config.cartStatusOptions
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova vendita' : 'Modifica vendita';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      userOptions() {
        return this.users.map((item)=>{
          return { text:item.surname + ' ' + item.name, fiscal_code: item.fiscal_code, value: item._id };
        });
      },
      kitOptions() {
        return this.kits.map((item)=>{
          return { text:item.title.it + " - " + item.genre.name.it, value: item._id };
        });
      },
      productOptions() {
        return this.products.filter((item)=>{
          if(!this.kit_id) return false
          if(this.editingItem.items.includes(item._id)) return false
          if(!item.is_seats_product && this.isOwned(item._id)) return false

          //if(this.userPurchases.map((pur) => pur.product_id).includes(item._id)) return false;
          return this.kit_id === item.kit._id;
        }).map((item)=>{
          return { text:item.title.it + "(" + item.sku + ")", value: item._id };
        });
      },
      subPauseErrorMessages() {
        const days = utils.toInt(this.editingItem.sub_pause_days, 0)
        if(days < 0) {
          return ['inserisci un numero maggiore di zero']
        }
        if(this.subPauseExtraAvailable - days < 0) {
          return ["non puoi acquistare più di " +this.settings.max_days_of_subscription_pause+ " giorni di pausa durante l'anno sportivo"]
        }
        return []
      },
      subPauseError() {
        return this.subPauseErrorMessages.length > 0
      },
      subPauseExtraAvailable() {
        const user = utils.getObjectInArray(this.users, '_id', this.editingItem.user_id)
        if(!user) return 0
        const now = DateTime.now()
        const pauses = this.pauses.filter(p => {
          if(p.user_id !== user._id) return false;
          const from = DateTime.fromISO(p.from)
          const to = DateTime.fromISO(p.to)
          return now > from && now < to
        })
        let num = 0;
        for(let i = 0; i < pauses.length; i++) {
          const p = pauses[i]
          num+=p.num_days;
        }

        return Math.max(0, this.settings.max_days_of_subscription_pause - num);
        //this.settings.max_days_of_subscription_pause
        //this.settings.extra_day_of_sub_pause_price
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection']),
      loadSettings() {
        this.loading = true;
        this.get({collection : 'settings'}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.settings = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento delle impostazioni del calendario';
          this.sendError({message});
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      addProduct() {
        if(!this.selectedProductId) return
        this.editingItem.items.push(this.selectedProductId)
        const p = this.getProduct(this.selectedProductId);
        if(p.expedition) {
          this.editingItem.expedition = true
        }
      },
      removeProduct( i ) {

        this.editingItem.items.splice(i, 1)
        this.editingItem.expedition = false
        for(let i = 0; i < this.editingItem.items.length; i++) {
          const p = this.getProduct(this.editingItem.items[i]);
          if(p.expedition) {
            this.editingItem.expedition = true
          }
        }
        if(this.editingItem.status === 'expedition' || this.editingItem.status === 'paid') {
          this.editingItem.status = 'closed'
        }
      },
      kitName(id) {
        const p = this.getProduct(id);
        if(p) {
          return p.kit.title.it;
        }
        return '-';
      },
      productName(id) {
        const p = this.getProduct(id);
        if(p) {
          return p.title.it;
        }
        return '-';
      },
      productPrice(id) {
        const p = this.getProduct(id);
        if(p) {
          return p.price + '€';
        }
        return '-';
      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      getProduct( id ) {
        for(let i = 0; i < this.products.length; i++) {
          const product = this.products[i];
          if(product._id === id) {
            return product;
          }
        }
        return null;
      },
      onUserSelected() {
        this.editingItem.card = false
        this.refreshPurchases();
        const cards = this.cards.filter(c => {
          if(c.user_id !== this.editingItem.user_id) return false
          const dt = DateTime.fromISO(c.expiration)
          return dt > DateTime.now()
        })
        if(!cards.length) {
          this.cardDialog = true
        }
      },
      addConiCardToCart() {
        this.editingItem.card = true
        this.cardDialog = false
      },
      isExpired(purchase) {
        if(!purchase.expiration) return false
        const dt = DateTime.fromISO(purchase.expiration)
        if(!dt.isValid) return true
        return dt < DateTime.now()
      },
      isOwned( productId ) {
        for(let i = 0; i < this.userPurchases.length; i++) {
          const purchase = this.userPurchases[i];
          if(purchase.product_id === productId && !purchase.consumed && !this.isExpired(purchase)) return true;
        }
        return false;
      },
      refreshPurchases() {

        let filter = {
          query : { user : this.editingItem.user_id },
          limit : 0,
          page : 1, sort : null, order : null
        };

        this.load({ collection : 'purchases', filter}).then((reply)=>{
          if(reply.data) {
            this.userPurchases = reply.data;
          }
        }).catch((err)=>{
          this.tableLoading = false;
          this.sendError({message:"Errore al caricamento degli elementi acquistati da questo utente."});
          if(config.debug) console.error("Errore al caricamento degli elementi acquistati da questo utente: " + err);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.userError = false;
        this.cityError = false;
        this.provError = false;
        this.capError = false;
        this.itemsError = false;
        this.addressError = false;
        this.puidError = false;
      },
      save( ) {
        this.clearErrors();

        if(this.expedition) {
          this.editingItem.status = 'paid'
        } else {
          this.editingItem.status = 'closed'
        }

        this.editingItem.evaded = true
        this.editingItem.payment_method = 'manual'
        if(!this.editingItem.payment_uid) this.editingItem.payment_uid = utils.genPaymentUID()
        this.editingItem.sub_pause_days = utils.toInt(this.editingItem.sub_pause_days, 0)

        this.insert({collection:'carts', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Vendita salvata"});
          this.$router.replace("/sells");
        }).catch((err)=>{
          if(config.debug) console.log("Save user error:", err);
          let message = err.message ? err.message : "errore al salvataggio della vendita";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {

      this.refreshCollection({collection: 'cards'})
      this.refreshCollection({collection: 'users'})
      this.refreshCollection({collection: 'pauses'})
      this.loadSettings()
      this.refreshCollection({collection: 'kits'}).then(()=>{
        this.refreshCollection({collection: 'products'})
      });
    }
  }
</script>

<style scoped></style>
